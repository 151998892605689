<i18n>
  {
    "es": {
      "CONFIRM_EMAIL": "Confirmar email",
      "CODE_SENT": "Hemos enviado un codigo de verificación a ",
      "NOT_RECEIVED": "¿No has recibido el código? Comprueba la bandeja de correo no deseado",
      "SEND_BUTTON": 'Verificar Código',
      "CODE_NOT_VALID": "El código no es válido",
      "ERROR_VERIFYING_CODE": "Error verificando el código",
      "CODE_VERIFIED": "Código verificado",
      "MAX_INTENTS": "Has superado el número máximo de intentos",
      "RESEND_CODE": "Reenviar código",
      "IN": "en",
    },
    "en": {
      "CONFIRM_EMAIL": "Confirm email",
      "CODE_SENT": "We have sent a verification code to ",
      "NOT_RECEIVED": "Haven't received the code? Check your spam folder",
      "SEND_BUTTON": 'Verify Code',
      "CODE_NOT_VALID": "The code is not valid",
      "ERROR_VERIFYING_CODE": "Error verifying the code",
      "CODE_VERIFIED": "Code verified",
      "MAX_INTENTS": "You have exceeded the maximum number of attempts",
      "RESEND_CODE": "Resend code",
      "IN": "in",
    },
    "fr": {
      "CONFIRM_EMAIL": "Confirmer l'email",
      "CODE_SENT": "Nous avons envoyé un code de vérification à ",
      "NOT_RECEIVED": "Vous n'avez pas reçu le code? Vérifiez votre dossier de courrier indésirable",
      "SEND_BUTTON": 'Vérifier le code',
      "CODE_NOT_VALID": "Le code n'est pas valide",
      "ERROR_VERIFYING_CODE": "Erreur lors de la vérification du code",
      "CODE_VERIFIED": "Code vérifié",
      "MAX_INTENTS": "Vous avez dépassé le nombre maximum de tentatives",
      "RESEND_CODE": "Renvoyer le code",
      "IN": "dans",
    },
    "de": {
      "CONFIRM_EMAIL": "E-Mail bestätigen",
      "CODE_SENT": "Wir haben einen Bestätigungscode an ",
      "NOT_RECEIVED": "Haben Sie den Code nicht erhalten? Überprüfen Sie Ihren Spam-Ordner",
      "SEND_BUTTON": 'Code überprüfen',
      "CODE_NOT_VALID": "Der Code ist ungültig",
      "ERROR_VERIFYING_CODE": "Fehler beim Überprüfen des Codes",
      "CODE_VERIFIED": "Code überprüft",
      "MAX_INTENTS": "Sie haben die maximale Anzahl von Versuchen überschritten",
      "RESEND_CODE": "Code erneut senden",
      "IN": "in",
    },
    "it": {
      "CONFIRM_EMAIL": "Conferma email",
      "CODE_SENT": "Abbiamo inviato un codice di verifica a ",
      "NOT_RECEIVED": "Non hai ricevuto il codice? Controlla la cartella dello spam",
      "SEND_BUTTON": 'Verifica codice',
      "CODE_NOT_VALID": "Il codice non è valido",
      "ERROR_VERIFYING_CODE": "Errore durante la verifica del codice",
      "CODE_VERIFIED": "Codice verificato",
      "MAX_INTENTS": "Hai superato il numero massimo di tentativi",
      "RESEND_CODE": "Rinvia codice",
      "IN": "in",
    },
    "pt": {
      "CONFIRM_EMAIL": "Confirmar email",
      "CODE_SENT": "Enviamos um código de verificação para ",
      "NOT_RECEIVED": "Não recebeu o código? Verifique a pasta de spam",
      "SEND_BUTTON": 'Verificar código',
      "CODE_NOT_VALID": "O código não é válido",
      "ERROR_VERIFYING_CODE": "Erro ao verificar o código",
      "CODE_VERIFIED": "Código verificado",
      "MAX_INTENTS": "Você excedeu o número máximo de tentativas",
      "RESEND_CODE": "Reenviar código",
      "IN": "em",
    }
  }
</i18n>

<template>
  <ModalCourtain :visibility="verificationCode" @close="cancel">
    <template v-slot:title>
      <div
        class="flex flex-col items-center px-4 justify-center w-full bg-white"
      >
        <h2 class="text-xl md:text-2xl font-bodoni font-bold text-center">
          {{ t('CONFIRM_EMAIL') }}
        </h2>
      </div>
    </template>
    <template v-slot:content>
      <p class="text-center leading-4 mt-3 px-4 text-xs font-normal">
        {{ t('CODE_SENT') }}
        <span class="font-bold">{{ customer?.email }}.</span>
      </p>
      <div class="w-full md:w-1/3 mx-auto px-4 mt-8">
        <div
          class="flex items-center space-x-4 w-full justify-center"
          ref="inputRefs"
        >
          <CodeInput
            v-model="firstNumber"
            @changeInput="handleChange($event, 0)"
            :status="inputStatus"
          />
          <CodeInput
            v-model="secondNumber"
            @changeInput="handleChange($event, 1)"
            :status="inputStatus"
          />
          <CodeInput
            v-model="thirdNumber"
            @changeInput="handleChange($event, 2)"
            :status="inputStatus"
          />
          <CodeInput
            v-model="fourthNumber"
            @changeInput="handleChange($event, 3)"
            :status="inputStatus"
          />
          <CodeInput
            v-model="fifethNumber"
            @changeInput="handleChange($event, 4)"
            :status="inputStatus"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer
      ><div class="mt-4 flex flex-col items-center space-y-4 px-4">
        <p class="text-center leading-4 text-xxs font-normal">
          {{ t('NOT_RECEIVED') }}
        </p>
        <button
          :disabled="!canResend"
          class="text-center leading-4 text-xs font-normal underline underline-offset-4 transition-all duration-300 ease-in-out"
          :class="
            canResend
              ? 'opacity-100 cursor-pointer hover:text-sanremo-primaryblue'
              : 'opacity-50 cursor-not-allowed'
          "
          :aria-label="t('RESEND_CODE')"
          type="button"
          @click="resendCode"
        >
          {{ t('RESEND_CODE') }}
          <span v-if="!canResend"
            >{{ t('IN') }} <span ref="timerCode">{{ timer }}</span></span
          >
        </button>
      </div></template
    >
  </ModalCourtain>
</template>

<script setup>
const {
  verificationCode,
  hideVerificationCodeModal,
  showCompleteProfileModal,
} = useAfterSignup()

const { t } = useI18n({
  useScope: 'local',
})

const { addMessage } = useSnackBar()

const route = useRoute()
const router = useRouter()
const { locale } = useI18n()

const { data: customer } = await useLazyAsyncData(() =>
  $fetch('/api/account/personaldata', {
    method: 'GET',
  })
)

const inputStatus = ref('INCOMPLETED')
const inputRefs = ref([])

const firstNumber = ref('')
const secondNumber = ref('')
const thirdNumber = ref('')
const fourthNumber = ref('')
const fifethNumber = ref('')

const canResend = ref(false)

const timer = ref('1:00')
let interval
const countdown = () => {
  clearInterval(interval)
  interval = setInterval(function () {
    timer.value = timer.value.split(':')
    let minutes = parseInt(timer.value[0])
    let seconds = parseInt(timer.value[1])

    seconds -= 1
    if (minutes < 0) return
    else if (seconds < 0 && minutes != 0) {
      minutes -= 1
      seconds = 59
    } else if (seconds < 10 && length.seconds != 2) seconds = '0' + seconds

    timer.value = minutes + ':' + seconds

    if (minutes == 0 && seconds == 0) clearInterval(interval)
  }, 1000)
}

watch(timer, () => {
  if (timer.value == '0:00') {
    canResend.value = true
  }
})

const code = ref('')
const codeValid = ref(false)

watch([verificationCode], () => {
  if (verificationCode.value) {
    if (inputRefs.value?.children?.length) inputRefs.value.children[0].focus()
    countdown()
  } else {
    clearInterval(interval)
    timer.value = '1:00'
    canResend.value = false
    inputStatus.value = 'INCOMPLETED'
    firstNumber.value = ''
    secondNumber.value = ''
    thirdNumber.value = ''
    fourthNumber.value = ''
    fifethNumber.value = ''
    code.value = ''
    codeValid.value = false
  }
})

onMounted(() => {
  if (verificationCode.value) {
    inputRefs.value.children[0].focus()
    countdown()
  }
  window.addEventListener('keyup', (e) => {
    if (!verificationCode.value) return
    if (e.isComposing || e.keyCode === 8) {
      if (inputStatus.value == 'SUCCESS') {
        return
      }
      if (inputIndex.value == 0) {
        const first = inputRefs?.value?.children[0]
        if (first) first.focus()
        return
      }
      if (!inputRefs.value.children[inputIndex.value - 1]) {
        return
      }
      inputRefs.value.children[inputIndex.value - 1].focus()
      inputRefs.value.children[inputIndex.value - 1].value = ''
      inputIndex.value -= 1
      return
    }
  })
})

const resendCode = async () => {
  canResend.value = false
  timer.value = '1:00'
  countdown()

  const { data, error } = await useAsyncData(
    'resendEmailVerificationCode',
    () =>
      $fetch('/api/account/personaldata/email/code/send', {
        method: 'GET',
      })
  )

  if (error.value) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('ERROR_SENDING_CODE'),
    })
    return
  }

  addMessage({
    type: 'success',
    result: 'OK',
    text: t('CODE_SENDED'),
  })
}

const buttonDisabled = computed(() => {
  return code.value.length < 5
})

const inputIndex = ref(0)

const handleChange = (event, index) => {
  inputIndex.value = Number(index)
  const number =
    firstNumber.value +
    secondNumber.value +
    thirdNumber.value +
    fourthNumber.value +
    fifethNumber.value
  if (number.length == 5) {
    validateCode()
    return
  }
  inputStatus.value = 'INCOMPLETED'
  if (event.target.value == '' || event.target.value.length > 1) {
    return
  }
  if (!inputRefs.value.children[index + 1]) {
    inputRefs.value.children[index].blur()
    return
  }
  inputRefs.value.children[index + 1].focus()
  inputIndex.value = index + 1
}

const actualIntents = ref(0)
const maxIntents = ref(5)

const validateCode = async () => {
  const code = Number(
    firstNumber.value +
      secondNumber.value +
      thirdNumber.value +
      fourthNumber.value +
      fifethNumber.value
  )
  if (!codeValid) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('CODE_NOT_VALID'),
    })
    return
  }

  const { error } = await useAsyncData('validateEmailCode', () =>
    $fetch('/api/account/personaldata/email/code/validate', {
      method: 'POST',
      body: {
        code: code,
      },
    })
  )

  if (error.value) {
    inputStatus.value = 'WRONG'
    actualIntents.value += 1
    if (actualIntents.value == maxIntents.value) {
      addMessage({
        type: 'error',
        result: 'ERROR',
        text: t('MAX_INTENTS'),
      })
    }
    return
  }

  inputStatus.value = 'SUCCESS'
  hideVerificationCodeModal()
  const actualRoute = route.path
  if (!actualRoute.includes('notifications')) {
    showCompleteProfileModal()
  }
}

const cancel = () => {
  hideVerificationCodeModal()
  const actualRoute = route.path
  if (!actualRoute.includes('notifications')) {
    showCompleteProfileModal()
  }
}
</script>
